import React from "react";
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { WORK_EXPERIENCES } from "../../constants/work";

const Work = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
      <Heading>
        Relevant Internship Experiences (Singapore)
      </Heading>
      {WORK_EXPERIENCES.map((work, index) => (
        <Box
          key={index}
          p={5}
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
        >
          <VStack align="start" spacing={3}>
            <Heading as="h3" size="md">{work.company}</Heading>
            <Text fontWeight="bold" fontSize="md">{work.position}</Text>
            <Text fontSize="sm" color="gray.500">{work.duration}</Text>
            <Text fontSize="md">{work.description}</Text>
          </VStack>
        </Box>
      ))}
    </Box>
  );
};
export default Work;
