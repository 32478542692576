import React from 'react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  Link,
} from '@chakra-ui/react';

const About: React.FC = () => {
  return (
    <VStack spacing={4} align="start">
      <Heading as="h1" size="xl" mb={4}>
        Contact Information
      </Heading>

      <HStack>
        <EmailIcon boxSize={6} />
        <Text>
          Email: 
          <Link href="mailto:mich0107@e.ntu.edu.sg" ml={2} color="blue.500">
            mich0107@e.ntu.edu.sg
          </Link>
          {' // '}
          <Link href="mailto:michaelac978@gmail.com" color="blue.500">
            michaelac978@gmail.com
          </Link>
        </Text>
      </HStack>

      <HStack>
        <PhoneIcon boxSize={6} />
        <Text>
          Phone (WhatsApp): 
          <Link href="tel:+6594289104" ml={2} color="blue.500">
            (+65) 9428 9104
          </Link>
        </Text>
      </HStack>

      <HStack>
        <Icon viewBox="0 0 24 24" boxSize={6}>
          <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-1.85 0-3.55-.63-4.9-1.69l.35-.37c1.17-1.18 1.95-1.59 2.83-1.59h.01c.87 0 1.63.42 2.83 1.59l.35.37C15.55 19.37 13.85 20 12 20zm6.9-4.69c-.6.74-1.32 1.34-2.1 1.79-.25-.43-.83-.99-1.8-1.76-1.18-1.03-2.01-1.44-2.99-1.44h-.01c-.97 0-1.81.41-2.99 1.44-.97.77-1.55 1.33-1.8 1.76-.78-.45-1.5-1.05-2.1-1.79-.45-.55-.8-1.18-1.05-1.82.27-.42.92-1.26 2.38-2.73C8.45 7.92 10.18 7 12 7s3.55.92 4.79 2.46c1.46 1.47 2.11 2.31 2.38 2.73-.25.64-.6 1.27-1.05 1.82zm-6.9-8.06c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
        </Icon>
        <Text>
          Telegram: 
          <Link href="https://t.me/michaelac9" ml={2} color="blue.500" target="_blank" rel="noopener noreferrer">
            @michaelac9
          </Link>
        </Text>
      </HStack>

      <HStack>
        <Icon viewBox="0 0 24 24" boxSize={6}>
          <path fill="currentColor" d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 20h-3v-9h3v9zm-1.5-10.28c-.97 0-1.76-.79-1.76-1.76s.79-1.76 1.76-1.76 1.76.79 1.76 1.76-.79 1.76-1.76 1.76zm13.5 10.28h-3v-4.72c0-1.12-.04-2.56-1.56-2.56-1.56 0-1.8 1.22-1.8 2.48v4.8h-3v-9h2.88v1.24h.04c.4-.76 1.38-1.56 2.84-1.56 3.04 0 3.6 2 3.6 4.6v5.72z"/>
        </Icon>
        <Text>
          LinkedIn: 
          <Link href="https://www.linkedin.com/in/michaelandrewchan/" ml={2} color="blue.500" target="_blank" rel="noopener noreferrer">
            linkedin.com/in/michaelandrewchan/
          </Link>
        </Text>
      </HStack>
    </VStack>
  );
};
export default About;
