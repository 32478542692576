
interface SingleWorkExperience {
  company: string;
  position: string;
  duration: string;
  description: string;
};

export const WORK_EXPERIENCES: SingleWorkExperience[] = [
  {
    company: 'SLB (Schlumberger)',
    position: 'Software Engineer Intern',
    duration: 'May 2024 - Present',
    description: '',
  },
  {
    company: 'Syngenta',
    position: 'Full-Stack Engineer Intern',
    duration: 'Nov 2023 - May 2024',
    description: '',
  },
  {
    company: 'Glints',
    position: 'Software Engineer Intern',
    duration: 'May 2023 - Nov 2023',
    description: '',
  },
]
