
interface SingleCCAExperience {
  company: string;
  position: string;
  duration: string;
  description: string;
};

export const CCA_EXPERIENCES: SingleCCAExperience[] = [
  {
    company: 'PINTU (NTU Indonesian Students Association)',
    position: 'Technology Director - Backend & DevOps',
    duration: 'Oct 2023 - Present (AY 2023/2024)',
    description: `
Handle the backend development and DevOps for PINTU's website.
    `,
  },

  {
    company: 'NTU Students\' Union Information Technology Committee',
    position: 'Chairperson (Development Lead)',
    duration: 'Sep 2022 - Present (AY 2022/2023, AY 2023/2024)',
    description: `
Lead the development of NTUSU's website and other IT projects.
    `,
  },

  {
    company: 'PINTU (NTU Indonesian Students Association)',
    position: 'Backend Developer',
    duration: 'AY 2022/2023 (Oct 2022 - Sep 2023)',
    description: `
Create REST API endpoints for PINTU's website.
    `,
  },

  {
    company: 'Machine Learning and Data Analytics (MLDA @EEE)',
    position: 'Research Officer',
    duration: 'Aug 2022 - Mar 2023',
    description: `
Conducted research from Huawei regarding Conditional Image Retrieval.
    `,
  },

  {
    company: 'NTU Students\' Union Information Technology Committee',
    position: 'Officer (Full-Stack Developer)',
    duration: 'Sep 2021 - Aug 2022 (AY 2021/2022)',
    description: `
Developed features for NTUSU's website and other IT projects.
    `,
  },
];
