import React from "react";
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { CCA_EXPERIENCES } from "../../constants/cca";

const CCA = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
      {CCA_EXPERIENCES.map((cca, index) => (
        <Box
          key={index}
          p={5}
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
          width="100%"
        >
          <VStack align="start" spacing={3}>
            <Heading as="h3" size="md">{cca.company}</Heading>
            <Text fontWeight="bold" fontSize="md">{cca.position}</Text>
            <Text fontSize="sm" color="gray.500">{cca.duration}</Text>
            <Text fontSize="md">{cca.description}</Text>
          </VStack>
        </Box>
      ))}
    </Box>
  );
};
export default CCA;
