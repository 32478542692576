import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { PageLayout } from "./components/PageLayout/PageLayout";
import About from "./pages/About/About";
import CCA from "./pages/CCA/CCA";
import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import Work from "./pages/Work/Work";

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageLayout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'projects',
        element: <Projects />
      },
      {
        path: 'cca',
        element: <CCA />
      },
      {
        path: 'work',
        element: <Work />
      },
      {
        path: 'about',
        element: <About />
      },
    ]
  }
]);
export default router;
