import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import { FaUsers, FaUser } from 'react-icons/fa';
import { PROJECTS_LIST } from "../../constants/projects";

const Projects: React.FC = () => {
  return (
    <Box display='flex' flexDir='column' alignItems='center' gap={4} width='100%' py={4}>
      {PROJECTS_LIST.map((project) => (
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
          width='100%'
          key={project.title}
        >
          <Image
            objectFit='cover'
            maxW={{ base: '100%', sm: '200px' }}
            src={project.image}
            alt={project.title}
          />
          <Stack>
            <CardBody paddingBottom={0}>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size='md'>
                  {project.title}
                </Heading>
                {project.isGroup ? (
                  <Tag ml={2} colorScheme='teal' size='sm'>
                    <TagLeftIcon boxSize='12px' as={FaUsers} />
                    <TagLabel>Group Project</TagLabel>
                  </Tag>
                ) : (
                  <Tag ml={2} colorScheme='blue' size='sm'>
                    <TagLeftIcon boxSize='12px' as={FaUser} />
                    <TagLabel>Individual Project</TagLabel>
                  </Tag>
                )}
              </Flex>
              <Text py='2'>
                {project.description}
              </Text>
            </CardBody>
            <CardFooter paddingTop={0}>
              <ButtonGroup spacing={4} size='sm'>
                {project.actions.map((action) => (
                  <Button
                    key={action.label}
                    variant='solid'
                    colorScheme='blue'
                    onClick={() => window.open(action.url, '_blank')}
                    isDisabled={action.isDisabled}
                  >
                    {action.label}
                  </Button>
                ))}
              </ButtonGroup>
            </CardFooter>
          </Stack>
        </Card>
      ))}
    </Box>
  );
};
export default Projects;
