
interface NavbarItem {
  path: string;
  label: string;
}

export const NAVBAR_ITEMS: NavbarItem[] = [
  {
    path: '/',
    label: 'Home'
  },
  {
    path: '/projects',
    label: 'Projects'
  },
  {
    path: '/cca',
    label: 'CCA'
  },
  {
    path: '/work',
    label: 'Work'
  },
  {
    path: '/about',
    label: 'About'
  },
]
