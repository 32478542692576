import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Heading,
  Button,
  useColorModeValue,
  Stack,
  Text,
} from '@chakra-ui/react'
import { NAVBAR_ITEMS } from "../../constants/navbar";

export const PageLayout: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" minHeight="100vh">
      <Box bg={useColorModeValue('cyan.200', 'cyan.900')} px={4} as='header'>
        <Flex h={16} alignItems='center' justifyContent='space-between'>
          <Heading size='md' onClick={() => navigate('/')} px={4} color='cyan.900' cursor='pointer'>
            Personal Website
          </Heading>
          <Stack direction='row' spacing={4} align='center' as='nav'>
            {NAVBAR_ITEMS.map((item) => (
              <Button
                key={item.path}
                onClick={() => navigate(item.path)}
                width='100px'
                colorScheme='blue'
                variant={window.location.pathname === item.path ? 'solid' : 'outline'}
              >
                {item.label}
              </Button>
            ))}
          </Stack>
        </Flex>
      </Box>
      <Container as='main' flex='1' maxW='container.xl' py={8}>
        <Outlet />
      </Container>
      <Box bg={useColorModeValue('cyan.400', 'cyan.900')} px={4} py={1} as='footer'>
        <Text fontSize='xs' textAlign='center' color='cyan.900'>
          &copy; 2024 Michael Andrew Chan
        </Text>
      </Box>
    </Flex>
  );
};
